import React, { Component } from "react";
import routes from "./routes";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router
} from "react-router-dom";

import Home from "./components/Home";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {routes.map((route, idx) => (
              <Route path={route.path} exact component={route.component} key={idx} />
            ))}
            <Route path="/:pageName" component={Home} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);