import React, { Component, Suspense } from 'react';
import { withRouter } from 'react-router-dom';

const Header = React.lazy(() => import('../Helper/Header'));
const HeroSection = React.lazy(() => import('./HeroSection'));
// const ResourceSection = React.lazy(() => import('./ResourceSection'));
const ResourceCustomerSupport = React.lazy(() => import('./ResourceCustomerSupport'));
const ResourceDigitalMarketing = React.lazy(() => import('./ResourceDigitalMarketing'));
const ResourceContentWriter = React.lazy(() => import('./ResourceContentWriter'));
const ResourceGraphicDesigner = React.lazy(() => import('./ResourceGraphicDesigner'));
const ResourceItSupport = React.lazy(() => import('./ResourceItSupport'));
const ResourceWebDesigner = React.lazy(() => import('./ResourceWebDesigner'));
const ServiceSection = React.lazy(() => import('./ServiceSection'));
const BenefitSection = React.lazy(() => import('./BenefitSection'));
const ProcessSection = React.lazy(() => import('./ProcessSection'));
const ContactSection = React.lazy(() => import('./ContactSection'));
const Footer = React.lazy(() => import('../Helper/Footer'));

class Layout1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                { id: 1, idnm: "home", navheading: "Home" },
                { id: 2, idnm: "resources", navheading: "Resources" },
                { id: 3, idnm: "services", navheading: "Services" },
                { id: 4, idnm: "benefits", navheading: "Benefits" },
                { id: 5, idnm: "process", navheading: "Process" },
                { id: 6, idnm: "contact", navheading: "Contacts" },
            ],
            pos: document.documentElement.scrollTop,
            imglight: true,
            navClass: "navbar-light"
        };
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > this.state.pos) {
            this.setState({ navClass: "navbar-light nav-sticky", imglight: false });
        }
        else {
            this.setState({ navClass: "navbar-light", imglight: true });
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="dot1"></div>
                        <div className="dot2"></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { match } = this.props;
        const { pageName } = match.params;

        return (
            <React.Fragment>
                <Suspense fallback={this.Loader()} >
                    <Header navItems={this.state.navItems} navClass={this.state.navClass} imglight={this.state.imglight} isLight={true} />
                    <HeroSection pageName={pageName} />
                    {/* <ResourceSection /> */}
                    {pageName && pageName === "customer-support" && <ResourceCustomerSupport />}
                    {pageName && pageName === "digital-marketing" && <ResourceDigitalMarketing />}
                    {pageName && pageName === "content-writer" && <ResourceContentWriter />}
                    {pageName && pageName === "graphic-designer" && <ResourceGraphicDesigner />}
                    {pageName && pageName === "it-support" && <ResourceItSupport />}
                    {pageName && pageName === "web-designer-developer" && <ResourceWebDesigner />}
                    <ServiceSection />
                    <BenefitSection />
                    <ProcessSection />
                    <ContactSection />
                    <Footer />
                </Suspense>
            </React.Fragment>
        );
    }
}

export default withRouter(Layout1);